// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bill-copra-js": () => import("../src/pages/bill-copra.js" /* webpackChunkName: "component---src-pages-bill-copra-js" */),
  "component---src-pages-bill-opa-js": () => import("../src/pages/bill-opa.js" /* webpackChunkName: "component---src-pages-bill-opa-js" */),
  "component---src-pages-bill-smart-js": () => import("../src/pages/bill-smart.js" /* webpackChunkName: "component---src-pages-bill-smart-js" */),
  "component---src-pages-events-js": () => import("../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-research-js": () => import("../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */)
}

